import Container from "../nav/Container";
import {Paths} from "../App";

export default function NotFound() {
    return(
        <Container>
            <section className="ls section_padding_150">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <p className="not_found yantramanov">
                                <span className="highlight">404</span>
                            </p>
                            <h2>Oops, page not found!</h2>
                            <p className="divider_15"/>
                            <p><a href={Paths.path_home} className="theme_button color1">Go Home</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    )
}

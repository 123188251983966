import Container from "../nav/Container";
import {Paths} from "../App";

import image from "../assets/css/images/cardio-monitoring.jpg"

export default function Service() {
    return (
        <Container>
            <section className="page_breadcrumbs ls ms ms2 section_padding_15 columns_margin_0 topmargin_40">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <ol className="breadcrumb greylinks">
                                <li><a href={Paths.path_home}>Home</a></li>
                                <li><a href={Paths.path_departments}>Services</a></li>
                                <li className="active"> Cardio Monitoring</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ls section_padding_150">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="section_header">Cardio Monitoring</h2>
                            <p className="bottommargin_30 bold">
                                Andouille tenderloin prosciutto tongue beef ribs. Kevin ham alcatra beef ribs boudin jowl. Picanha beef ribs short ribs
                                tenderloin swine. Kevin short loin tongue flank beef landjaeger pork loin biltong sausage pork andouille t-bone cow.
                            </p>
                            <img src={image} alt="" className="bottommargin_30"/>
                            <p className="bottommargin_10">
                                Shank corned beef boudin, hamburger pig cupim ground round landjaeger. Spare ribs pork frankfurter drumstick sirloin cupim
                                salami. Ground round t-bone tail shank jerky, strip steak flank ribeye pancetta turducken salami cupim shoulder turkey swine.
                                Short
                                ribs meatloaf corned beef pastrami, tail pork belly alcatra filet mignon hamburger shoulder. Boudin short ribs pastrami, ham
                                hock sirloin corned beef tenderloin pig. Pork ribeye ground round tenderloin chicken ham turducken sausage pancetta swine.
                                Pork chop sirloin capicola, ribeye cupim ball tip tri-tip short loin bacon turducken boudin.
                            </p>
                            <ol className="list3 highlight3 darklinks">
                                <li>
                                    <a href="single-service.html">Lorem ipsum dolor sit amet</a>
                                </li>
                                <li>
                                    <a href="single-service.html">Sint animi non ut sed</a>
                                </li>
                                <li>
                                    <a href="single-service.html">Eaque blanditiis nemo</a>
                                </li>
                                <li>
                                    <a href="single-service.html">Amet, consectetur adipisicing</a>
                                </li>
                            </ol>
                            <p>
                                Strip steak ham swine pork loin fatback prosciutto meatloaf jowl pig sirloin drumstick porchetta. Beef ribs t-bone shoulder
                                tenderloin andouille. Biltong turducken strip steak rump shoulder venison short loin t-bone landjaeger tri-tip tail doner ground
                                round.
                            </p>
                            <h4>Cow corned beef tenderloin</h4>
                            <p className="bottommargin_10">
                                Fatback beef ribs corned beef frankfurter tenderloin turducken, shank t-bone doner capicola filet mignon kevin beef ham hock
                                pork belly. Spare ribs fatback leberkas brisket, tongue bresaola kevin rump cupim short loin shank kielbasa pork belly ground
                                round. Shank chicken capicola shoulder alcatra, doner brisket rump tail landjaeger. Ribeye jowl fatback pastrami picanha. Filet
                                mignon ham hock pork belly, bresaola corned beef leberkas short loin pork chop chuck frankfurter ham tail landjaeger.
                                Spare ribs shank ribeye swine tongue.Fatback capicola shoulder kielbasa tail pork belly pork chop landjaeger pork loin. Biltong
                                pork belly shank meatloaf ham hock swine alcatra shankle pig. Frankfurter short ribs tenderloin.
                            </p>
                            <ul className="list2 darklinks">
                                <li>
                                    <a href="single-service.html">Lorem ipsum dolor sit amet</a>
                                </li>
                                <li>
                                    <a href="single-service.html">Sint animi non ut sed</a>
                                </li>
                                <li>
                                    <a href="single-service.html">Eaque blanditiis nemo</a>
                                </li>
                                <li>
                                    <a href="single-service.html">Amet, consectetur adipisicing</a>
                                </li>
                            </ul>
                            <p>
                                Ground round ball tip kevin pork chop hamburger alcatra, jerky bresaola pig landjaeger sirloin. Doner jerky shank capicola,
                                turkey tenderloin ham bresaola pork swine fatback cupim ribeye drumstick. Alcatra prosciutto tail, porchetta strip steak pork
                                jerky
                                cupim.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </Container>
    )
}

import {Paths} from "../App";

import logo from "../assets/css/images/logo.png"
import * as Path from "path";

export default function Footer() {
    return (
        <>
            <footer className="page_footer template_footer cs main_bg_color2">
                <div className="container">
                    <div className="row display_table_sm divider_40">
                        <div className="col-sm-4 col-sm-push-4 text-center display_table_cell_sm">
                            <a href={Paths.path_home} className="logo">
                                <img src={logo} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-sm-pull-4 text-center text-sm-left contact-teaser display_table_cell_sm">
                            <span className="small-text">Office contacts</span>
                            <p className="yantramanov weight-black fontsize_18">
                                +256 (0)393 194664<br/>+256 (0)200 908666
                            </p>
                        </div>
                        <div className="col-sm-4 text-center text-sm-right contact-teaser display_table_cell_sm">
                            <span className="small-text">online consultation</span>
                            <p className="yantramanov weight-black fontsize_18">
                                info@tennaandpharma.com
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            <section className="page_copyright ds parallax section_padding_15 table_section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 text-sm-left text-center greylinks">
                            <p>&copy; 2022 Tenna & Pharma Lab</p>
                        </div>
                        <div className="col-sm-2 text-center">
                            <a href={Paths.path_home} className="logo bottom_logo grey">
                                <img src={logo} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-5 text-sm-right">
                            <div className="widget widget_nav_menu darklinks">
                                <ul className="menu">
                                    <li className="">
                                        <a href={Paths.path_home}>Home</a>
                                    </li>
                                    <li className="">
                                        <a href={Paths.path_about}>About</a>
                                    </li>
                                    <li className="">
                                        <a href={Paths.path_services}>Services</a>
                                    </li>
                                    <li className="">
                                        <a href={Paths.path_contacts}>Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

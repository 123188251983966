import React from "react";
import {Paths} from "../App";
import Container from "../nav/Container";

import slider_1 from '../assets/images/home/home_3.jpeg'
import slider_2 from '../assets/images/home/home_1.jpeg'
import slider_3 from '../assets/images/home/home_2.jpeg'

import img_services from "../assets/css/images/general.png"
import img_lab from "../assets/css/images/laboratory.png"
import img_symptom from '../assets/css/images/symptom.png'
import img_cadio from '../assets/css/images/cardio.png'

import img_consult from '../assets/css/images/consultation.png'
import img_price from '../assets/css/images/prices.png'
import img_doctor from '../assets/css/images/doctors.png'
import img_staff from '../assets/css/images/staff.png'
import img_open from '../assets/css/images/opened.png'
import img_happy from '../assets/css/images/happy.png'

export default function Home() {

    const load_slider = () => {
        const introSlider = jQuery(".intro_section .flexslider")
        console.log(introSlider)

        const start_after = (slider: any) => {
            slider.find('.flex-active-slide .slide_description').children().each(function (index: number) {
                // @ts-ignore
                const self: any = jQuery(this)
                const animationClass = !self.data('animation') ? 'fadeInRight' : self.data('animation');
                setTimeout(function () {
                    self.addClass("animated " + animationClass);
                }, index * 200);
            });
        }

        introSlider.each(function (index) {
            const currentSlider = jQuery(this);
            (currentSlider as any).flexslider({
                animation: "fade", pauseOnHover: true, useCSS: true, controlNav: false, directionNav: true,
                controlsContainer: ".slide_description", prevText: "", nextText: "", smoothHeight: false,
                slideshowSpeed: 10000, animationSpeed: 600,
                start: function (slider: any) {
                    slider.find('.slide_description').children().css({'visibility': 'hidden'});
                    start_after(slider)
                },
                after: function (slider: any) {
                    start_after(slider)
                },
                end: function (slider: any) {
                    slider.find('.slide_description').children().each(function () {
                        // @ts-ignore
                        const self = jQuery(this);
                        const animationClass = !self.data('animation') ? 'fadeInRight' : self.data('animation');
                        self.removeClass('animated ' + animationClass).css({'visibility': 'hidden'});
                    });
                },

            })
                //wrapping nav with container
                .find('.flex-direction-nav').css('visibility', 'hidden');
            // .addClass('to_animate')
        });

        jQuery(".flexslider").each(function (index) {
            const currentSlider = jQuery(this);
            if (currentSlider.find('.flex-active-slide').length) {
                return;
            }
            (currentSlider as any).flexslider({
                animation: "fade", useCSS: true, controlNav: true, directionNav: false, prevText: "",
                nextText: "", smoothHeight: false, slideshowSpeed: 5000, animationSpeed: 800,
            })
        });
    }

    React.useLayoutEffect(() => {
        load_slider()
    }, [])

    return (
        <Container>
            {/*The slider*/}
            <section className="intro_section page_mainslider">
                <div className="flexslider">
                    <ul className="slides">
                        {
                            [
                                {src: slider_1, txt1: 'Empowering People', txt2: 'to Improve Their Lives'},
                                {src: slider_2, txt1: 'Higher standards', txt2: 'of care every day'},
                                {src: slider_3, txt1: ' Leading the way', txt2: 'to better healthcare'}
                            ]
                                .map((slide, index) =>
                                    <li key={index}>
                                        <img src={slide.src} alt=""/>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="slide_description_wrapper">
                                                        <div className="slide_description highlight">
                                                            <div className="intro-layer" data-animation="fadeInLeft">
                                                                <p className="yantramanov text-uppercase fontsize_35">
                                                                    {slide.txt1}
                                                                </p>
                                                            </div>
                                                            <div className="intro-layer" data-animation="fadeInRight">
                                                                <p className="text-uppercase yantramanov big">
                                                                    {slide.txt2}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                        }
                    </ul>
                </div>
            </section>

            <section className="ls ms section_padding_25 page_info_banners columns_padding_0 columns_margin_0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-md-offset-2">
                            <div className="with_padding cs main_bg_color info_banner">
                                <h4 className="text-uppercase bottommargin_30">Working hours</h4>
                                <ul className="list1 no-bullets middle-borders bottommargin_0">
                                    <li>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-clock-o"></i>
                                            </div>
                                            <div className="media-body small-text2 thin">
                                                Monday - Saturday
                                                <span className="pull-right">08:00 AM – 05:00 PM</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-clock-o"></i>
                                            </div>
                                            <div className="media-body small-text2 thin">
                                                Public Holidays
                                                <span className="pull-right">09:00 AM – 04:00 PM</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-clock-o"></i>
                                            </div>
                                            <div className="media-body small-text2 thin">
                                                Sundays
                                                <span className="pull-right">09:00 AM – 04:00 PM</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="with_padding cs main_bg_color2 info_banner">
                                <h4 className="text-uppercase bottommargin_30">Contact details</h4>
                                <ul className="list1 no-bullets middle-borders bottommargin_0">
                                    <li>
                                        <div className="media bottommargin_10">
                                            <div className="media-left">
                                                <i className="fa fa-map-marker"></i>
                                            </div>
                                            <div className="media-body small-text2 thin">
                                                Plot 1515, Kyadondo Block 213<br/> Bukoto Kampala
                                            </div>
                                        </div>
                                    </li>
                                    <li className="topmargin_10">
                                        <a href={Paths.path_contacts} className="theme_button inverse block_button margin_0">
                                            Get directions on the map
                                            <i className="fa fa-caret-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ls section_padding_top_150 section_padding_bottom_120 features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-offset-4 col-md-8">
                            <h2 className="section_header">What Makes Us Different</h2>
                            <p className="bold bottommargin_30">
                                On the other hand, we denounce with righteous indignation and dislike men who are so beguiled
                                and demoralized by the charms of the moment.
                            </p>
                            <hr className="divider_15"/>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="media img-media-teaser">
                                        <div className="media-left media-middle">
                                            <img src={img_consult} alt=""/>
                                        </div>
                                        <div className="media-body media-middle">
                                            <h4 className="bold text-uppercase margin_0">
                                                <a href="#">Free<br/> Consultation</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="media img-media-teaser">
                                        <div className="media-left media-middle">
                                            <img src={img_price} alt=""/>
                                        </div>
                                        <div className="media-body media-middle">
                                            <h4 className="bold text-uppercase margin_0">
                                                <a href="#">Affordable<br/>Prices</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="divider_15"/>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="media img-media-teaser">
                                        <div className="media-left media-middle">
                                            <img src={img_doctor} alt=""/>
                                        </div>
                                        <div className="media-body media-middle">
                                            <h4 className="bold text-uppercase margin_0">
                                                <a href="#">Qualified<br/>Doctors</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="media img-media-teaser">
                                        <div className="media-left media-middle">
                                            <img src={img_staff} alt=""/>
                                        </div>
                                        <div className="media-body media-middle">
                                            <h4 className="bold text-uppercase margin_0">
                                                <a href="#">Professional<br/>Staff</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="divider_15"/>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="media img-media-teaser">
                                        <div className="media-left media-middle">
                                            <img src={img_open} alt=""/>
                                        </div>
                                        <div className="media-body media-middle">
                                            <h4 className="bold text-uppercase margin_0">
                                                <a href="#">24/7<br/>Opened</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="media img-media-teaser">
                                        <div className="media-left media-middle">
                                            <img src={img_happy} alt=""/>
                                        </div>
                                        <div className="media-body media-middle">
                                            <h4 className="bold text-uppercase margin_0">
                                                <a href="javascript:void(0)">~50000 Happy<br/> Clients</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ds ms section_padding_top_150 section_padding_bottom_100 page_services parallax">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 text-center">
                            <h2 className="section_header highlight3">Our Laboratory Services</h2>
                            <p className="bold grey">
                                Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut
                                et voluptates repudiandae sint et molestiae non recusandae
                            </p>
                        </div>
                    </div>
                    <div className="row topmargin_50 columns_margin_bottom_60">
                        <div className="col-md-4 col-sm-6 text-center">
                            <div className="teaser">
                                <img src={img_cadio} alt=""/>
                                <h4 className="grey">
                                    <a href={Paths.path_services}>Cardio Monitoring</a>
                                </h4>
                                <p>
                                    These cases are perfectly simple and easy to distinguish a free hour when our power
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6 text-center">
                            <div className="teaser">
                                <img src={img_symptom} alt=""/>
                                <h4 className="grey">
                                    <a href={Paths.path_services}>Symptom Check</a>
                                </h4>
                                <p>
                                    Mauris consectetur dui eget ullamcorper mehendrerit eros ligula consectetur
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center">
                            <div className="teaser">
                                <img src={img_lab} alt=""/>
                                <h4 className="grey">
                                    <a href={Paths.path_services}>Laboratory Test</a>
                                </h4>
                                <p>
                                    Pellentesque facilisis.sollicitudin justo non odio molestie, sed venenatis elit laoreet
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center">
                            <div className="teaser">
                                <img src={img_services} alt=""/>
                                <h4 className="grey">
                                    <a href={Paths.path_services}>General Analysis</a>
                                </h4>
                                <p>
                                    Morbi sollicitudin justo non odio molestie sed venenatis elit laoreet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    )
}

import {Paths} from "../App";
import logo from "../assets/css/images/logo.png"

export default function Header() {
    return (
        <>
            <div id="canvas">
                <div id="box_wrapper">
                    <section className="page_toplogo table_section table_section ls section_padding_20">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4 col-sm-push-4 text-center">
                                    <a href={Paths.path_home} className="logo">
                                        <img src={logo} alt=""/>
                                    </a>
                                </div>
                                <div className="col-sm-4 col-sm-pull-4 text-center text-sm-left contact-teaser">
                                    <span className="small-text">Office contact</span>
                                    <p className="yantramanov weight-black fontsize_18">+256 (0)393 194664</p>
                                </div>
                                <div className="col-sm-4 text-center text-sm-right contact-teaser">
                                    <span className="small-text">online consultation</span>
                                    <p className="yantramanov weight-black fontsize_18">
                                        info@tennaandpharma.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>


                    <header className="page_header header_color template_header table_section toggle_menu_left">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row cs rounded header-block">

                                        <div className="col-md-9">
                                            <nav className="mainmenu_wrapper">
                                                <ul className="mainmenu nav sf-menu">
                                                    <li className="active">
                                                        <a href={Paths.path_home}>Home</a>
                                                    </li>
                                                    <li>
                                                        <a href={Paths.path_about}>About Us</a>
                                                    </li>
                                                    <li>
                                                        <a href={Paths.path_departments}>Departments</a>
                                                    </li>
                                                    <li>
                                                        <a href={Paths.path_contacts}>Contacts</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <span className="toggle_menu">
										<span></span>
									</span>
                                        </div>

                                        <div className="col-md-3 text-right">
                                            <div className="page_social greylinks">
                                                <a href="#" className="social-icon soc-facebook"></a>
                                                <a href="#" className="social-icon soc-twitter"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
        </>
    )
}

import React from 'react';
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Contacts from "./pages/Contacts";
import Service from "./pages/Service"
import Departments from "./pages/Departments";
import About from "./pages/About";
import NotFound from "./pages/404";

export default function App() {
    return (
        <>
            <Routes>
                <Route path={Paths.path_home} element={<Home/>}/>
                <Route path={Paths.path_contacts} element={<Contacts/>}/>
                <Route path="services/:department" element={<Service/>}/>
                <Route path={Paths.path_departments} element={<Departments/>}/>
                <Route path={Paths.path_about} element={<About/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    )
}

export class Paths {
    static path_home = "/"
    static path_contacts = "contacts"
    static path_about = "about"
    static path_services = "services"
    static path_departments = "departments"
}

import Container from "../nav/Container";

import image_1 from "../assets/images/about/about_2.jpeg"
import image_2 from "../assets/images/about/about_1.jpeg"

export default function About() {
    return (
        <Container>

            <section className="ls section_padding_150">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7 col-md-8 col-lg-8">
                            <p className="bottommargin_30">
                                <img src={image_1} alt="" className="alignleft"/>
                                Tenna & Pharma Laboratories is one of the leading private pathology laboratories located
                                in Bukoto, Kampala operating throughout Uganda, providing quality, vital diagnostic and
                                Reliable medical services.
                            </p>
                            <p>
                                Tenna and Pharma Laboratories was started in 2021. The facility opened in Times of
                                COVID 19 Pandemic when the country had had a significant rise in community infections
                                and there were few laboratories that had machines that could do COVID 19 PCR testing.
                            </p>
                            <div className="row topmargin_60 bottommargin_60">
                                <div className="col-md-10 col-md-offset-1">
                                    <blockquote className="no-border text-center">
                                        <p className="yantramanov highlight">
                                            “We came up with the idea and with guidance of medical professionals were able to put
                                            up a fully functional Molecular Laboratory.”</p>
                                        <div className="item-meta small-text2">

                                        </div>
                                    </blockquote>
                                </div>
                            </div>
                            <p>
                                With time Tenna & Pharma Laboratories has grown to provide a wide range of specialized
                                diagnostic services covering molecular Diagnostics, PCR Diagnostics,
                                Microbiology (Culture and Sensitivity testing), Clinical Chemistry, Hematology,
                                Microscopy, & Consultancy in Laboratory Services.
                            </p>
                            <p>
                                <img src={image_2} alt="" className="alignright"/>
                                In Conjunction with an extended Radiology department that offers quality diagnostic imaging
                                for both X-Ray and Ultra-sound Scan. <br/><br/>
                                Tenna & Pharma is dedicated to improving peoples
                                lives by providing the highest quality and yet affordable diagnostic services.
                            </p>
                        </div>

                        <aside className="col-sm-5 col-md-4 col-lg-4">
                            <div className="panel-group" id="accordion1">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion1" href="#collapse1" className="collapsed">
                                                <i className="rt-icon2-envelope"></i>
                                                Mission
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse1" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            To provide quality medical diagnostic services in the shortest possible turn
                                            around T\time that will impact positively on the Treatment, monitoring,
                                            and management of disease in communities.
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion1" href="#collapse2" className="collapsed">
                                                <i className="rt-icon2-envelope"></i>
                                                Vision
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse2" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            To be the leading Laboratory providing quality, highly innovative diagnostic services to the people and provide a
                                            center of excellency in Research & Disease Surveillance.
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion1" href="#collapse4" className="collapsed">
                                                <i className="rt-icon2-envelope"></i>
                                                Motto
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse4" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            Nothing but the best.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>

                    <div className="row">

                        <div className='col-xs-12'>
                            <h2 className="section_header">Customer Service</h2>
                        </div>
                        <div className={'col-xs-12 col-md-4'}>
                            <img src={image_1} alt=""/>
                        </div>
                        <div className='col-xs-12 col-md-8'>
                            <p className="bottommargin_30">
                                Tenna and Pharma Laboratories offers value and respect to our customers, assuring that every visit provides the greatest of
                                customer care. This is achieved by emphasizing on our patients’ health. We provide a professional and friendly team, an
                                effective sample collection network and customer-care service. A confidential electronic system is also available for delivery
                                of results.
                            </p>
                            <p>
                                Our reception and the staff are a key part of our customer care, as this is the area where we deal with our clients face to
                                face. Professional treatment of our patients with empathy and dignity is of great importance to us.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    )
}

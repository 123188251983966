import Container from "../nav/Container";
import marker_icon from '../assets/css/images/map_marker_icon.png';
import React from "react";
import {Utils} from "../utils/Utils";

export default function Contacts() {
    const init_map = () => {
        Utils.loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyC84G-d3x2s4hEni0wLcGDHBcGGEpBLj2s",
            () => {
                //Google Map script
                const $googleMaps = jQuery('#map, .page_map');
                if ($googleMaps.length) {
                    $googleMaps.each(function () {
                        const $map = jQuery(this);

                        let lat: number;
                        let lng: number;
                        let map: any;

                        //map styles. You can grab different styles on https://snazzymaps.com/
                        const styles = [
                            {"featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{"color": "#444444"}]},
                            {"featureType": "administrative.country", "elementType": "geometry.fill", "stylers": [{"visibility": "on"}]},
                            {
                                "featureType": "administrative.province", "elementType": "labels.icon",
                                "stylers": [{"hue": "#FF0000"}, {"visibility": "on"}]
                            },
                            {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#F2F2F2"}]},
                            {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]},
                            {"featureType": "road", "elementType": "all", "stylers": [{"saturation": -100}, {"lightness": 45}]},
                            {"featureType": "road.highway", "elementType": "all", "stylers": [{"visibility": "simplified"}]},
                            {"featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{"visibility": "off"}]},
                            {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]},
                            {"featureType": "water", "elementType": "all", "stylers": [{"color": "#46BCEC"}, {"visibility": "on"}]}];

                        //map settings
                        const address = $map.data('address') ? $map.data('address') : 'london, baker street, 221b';
                        const markerDescription = $map.find('.map_marker_description').prop('outerHTML');

                        //if you do not provide map title inside #map (.page_map) section inside H3 tag - default title (Map Title) goes here:
                        const markerTitle = $map.find('h3').first().text() ? $map.find('h3').first().text() : 'Map Title';
                        const markerIconSrc = $map.find('.map_marker_icon').first().attr('src');

                        //type your address after "address="
                        jQuery.getJSON(
                            `http://maps.googleapis.com/maps/api/geocode/json?address=${address}`,
                            function (data: any) {
                                lat = data.results[0].geometry.location.lat;
                                lng = data.results[0].geometry.location.lng;
                            },
                            function () {
                                const center = new google.maps.LatLng(lat, lng);
                                const settings = {
                                    mapTypeId: google.maps.MapTypeId.ROADMAP, zoom: 16, draggable: false, scrollwheel: false,
                                    center: center, styles: styles
                                }
                                map = new google.maps.Map($map[0], settings);

                                const marker = new google.maps.Marker({position: center, title: markerTitle, map: map, icon: markerIconSrc,})

                                const infoWindow = new google.maps.InfoWindow({content: markerDescription});

                                google.maps.event.addListener(marker, 'click', function () {
                                    infoWindow.open(map, marker);
                                })
                            }
                        )
                    })
                }
            }
        )
    }

    React.useLayoutEffect(() => {
        init_map()
    }, [])

    return (
        <Container>
            <section className="ls section_padding_top_100 section_padding_bottom_75">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <section id="map" className="ls ms" data-address="14 Harriet Walk Belgravia, London">
                                <div className="map_marker_description">
                                    <h3>Map Title</h3>
                                    <p>Map description text</p>
                                    <img className="map_marker_icon" src={marker_icon} alt=""/>
                                </div>
                            </section>
                        </div>
                    </div>

                    <div className="row topmargin_40">
                        <div className="col-sm-4 to_animate" data-animation="pullDown">
                            <div className="teaser text-center">
                                <div className="teaser_icon highlight size_normal">
                                    <i className="rt-icon2-phone5"></i>
                                </div>
                                <p>
                                    <span className="grey">Office:</span>+256 (0)393 194664<br/>
                                    <span className="grey">Mobile:</span>+256 (0)788 264040<br/>
                                    <span className="grey">Mobile:</span> +256 (0)788 264040
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-4 to_animate" data-animation="pullDown">
                            <div className="teaser text-center">
                                <div className="teaser_icon highlight size_normal">
                                    <i className="rt-icon2-location2"></i>
                                </div>

                                <p>
                                    <br/>Plot 1515, Kyadondo Block 213,
                                    <br/>Bukoto, Kampala, Uganda
                                </p>

                            </div>
                        </div>
                        <div className="col-sm-4 to_animate" data-animation="pullDown">
                            <div className="teaser text-center">
                                <div className="teaser_icon highlight size_normal">
                                    <i className="rt-icon2-mail"></i>
                                </div>
                                <p>info@tennaandpharmalab.com</p>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm-12 to_animate">
                            <form className="contact-form columns_padding_5" method="post" action="https://html.modernwebtemplates.com/valeo/">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <p className="contact-form-name">
                                            <label htmlFor="name">Full Name
                                                <span className="required">*</span>
                                            </label>
                                            <input type="text" aria-required="true" size={30} value="" name="name" id="name" className="form-control"
                                                   placeholder="Full Name"/>
                                        </p>
                                        <p className="contact-form-email">
                                            <label htmlFor="email">Email address
                                                <span className="required">*</span>
                                            </label>
                                            <input type="email" aria-required="true" size={30} value="" name="email" id="email" className="form-control"
                                                   placeholder="Email"/>
                                        </p>
                                        <p className="contact-form-subject">
                                            <label htmlFor="subject">Subject
                                                <span className="required">*</span>
                                            </label>
                                            <input type="text" aria-required="true" size={30} value="" name="subject" id="subject" className="form-control"
                                                   placeholder="Subject"/>
                                        </p>
                                    </div>
                                    <div className="col-sm-6">

                                        <p className="contact-form-message">
                                            <label htmlFor="message">Message</label>
                                            <textarea aria-required="true" rows={7} cols={45} name="message" id="message" className="form-control"
                                                      placeholder="Message"></textarea>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <p className="contact-form-submit text-center topmargin_30">
                                            <button type="submit" id="contact_form_submit" name="contact_submit" className="theme_button color1">Send Message
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    )
}

import Header from "./Header";
import Footer from "./Footer";
import {PropsWithChildren} from "react";

export default function Container(params: PropsWithChildren<{}>) {
    return (
        <>
            <div id="canvas">
                <div id="box_wrapper">
                    <Header/>
                    {params.children}
                    <Footer/>
                </div>
            </div>
        </>
    )
}

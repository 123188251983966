import Container from "../nav/Container";

import img_1 from "../assets/css/images/gallery/12.jpg"
import img_2 from '../assets/css/images/gallery/11.jpg'
import img_3 from '../assets/css/images/gallery/02.jpg'
import img_4 from '../assets/css/images/gallery/03.jpg'
import img_5 from '../assets/css/images/gallery/06.jpg'
import img_6 from '../assets/css/images/gallery/01.jpg'
import {Paths} from "../App";

export default function Departments() {
    return (
        <Container>

            <section className="ls ms section_padding_top_150 section_padding_bottom_100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="section_header">Our Departments</h2>
                            <p className="bottommargin_30 bold">
                                Tenna and Pharma Laboratories offers a wide range of medical services that are categorized into three sections
                            </p>
                        </div>
                    </div>
                    <div className="isotope_container isotope row masonry-layout topmargin_30">
                        <div className="col-md-4 col-sm-6 isotope-item">
                            <article className="vertical-item content-padding with_background text-center">
                                <div className="item-media">
                                    <img src={img_1} alt=""/>
                                </div>
                                <div className="item-content">
                                    <h4 className="entry-title bottommargin_30">
                                        <a href={`${Paths.path_services}/1`}>Section 1</a>
                                    </h4>
                                    <p>
                                        Has the Diagnostic Laboratory that comprises of different departments; Hematology, Clinical Chemistry, Molecular
                                        Biology, Microbiology, and Immunology.
                                    </p>
                                </div>
                            </article>
                        </div>
                        <div className="col-md-4 col-sm-6 isotope-item">
                            <article className="vertical-item content-padding with_background text-center">
                                <div className="item-media">
                                    <img src={img_2} alt=""/>
                                </div>
                                <div className="item-content">
                                    <h4 className="entry-title bottommargin_30">
                                        <a href={`${Paths.path_services}/2`}>Section 2</a>
                                    </h4>
                                    <p>Radiology Department that is composed of an Ultra-sound Scan and the Digital X-Ray</p>
                                </div>
                            </article>
                        </div>
                        <div className="col-md-4 col-sm-6 isotope-item">
                            <article className="vertical-item content-padding with_background text-center">
                                <div className="item-media">
                                    <img src={img_3} alt=""/>
                                </div>
                                <div className="item-content">
                                    <h4 className="entry-title bottommargin_30">
                                        <a href={`${Paths.path_services}/3`}>Section 3</a>
                                    </h4>
                                    <p>Immunization services (Yellow Fever)</p>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="with_border padding_10">
                                <h4 className="entry-title semibold bottommargin_20">
                                    CLINICAL CHEMISTRY DEPARTMENT
                                </h4>

                                <div className="entry-content">
                                    <p>
                                        Clinical chemistry also known as Clinical pathology is the area of medicine that is generally concerned with analysis of
                                        bodily fluids for diagnostic and therapeutic purposes. These tests are performed on any kind of body fluid, but mostly
                                        on serum or plasma.
                                        <br/>Clinical chemistry tests can be categorized into sub-specialties of:
                                    </p>
                                    <ul className="list2 darklinks">
                                        <li>
                                            General or routine chemistry – commonly ordered blood chemistry (e.g., liver and kidney function tests, lipid
                                            profile, Electrolytes).
                                        </li>
                                        <li>Special chemistry - elaborate techniques such as electrophoresis, and manual testing methods.</li>
                                        <li>Clinical endocrinology– the study of hormones, and diagnosis of endocrine disorders.</li>
                                        <li>Toxicology – the study of drugs of abuse and other chemicals.</li>
                                        <li>Therapeutic Drug Monitoring – measurement of therapeutic medication levels to optimize dosage.</li>
                                        <li>
                                            Urinalysis – chemical analysis of urine for a wide array of diseases, along with other fluids such as CSF and
                                            effusions
                                        </li>
                                        <li>
                                            Fecal analysis – mostly for detection of gastrointestinal disorders.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="with_border padding_10">
                                <h4 className="entry-title semibold bottommargin_20">HEMATOLOGY DEPARTMENT</h4>
                                <div className="entry-content">
                                    <p>
                                        Hematology involves the study of the blood, in particular how blood can affect overall health or disease. Hematology
                                        tests include tests on the blood, blood proteins and blood-producing organs.
                                        These tests can evaluate a variety of blood conditions including infection, anemia, inflammation, hemophilia,
                                        blood-clotting disorders, leukemia and the body’s response to chemotherapy treatments.
                                    </p>
                                    <p>
                                        Tests may be routine and regular, or they may be called upon to diagnose serious conditions in urgent situations. In
                                        many cases, the results of a blood test can give an accurate assessment of body conditions and how internal or external
                                        influences may affect a patient’s health
                                        Examples of the tests include: Complete blood count (CBC), Erythrocyte Sedimentation Rate (ESR), Platelet Count, Blood
                                        typing and many others
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="with_border padding_10">
                                <h4 className="entry-title semibold bottommargin_20">MICROBIOLOGY DEPARTMENT</h4>
                                <div className="entry-content">
                                    <p>
                                        Microbiology tests identify all disease causative living organisms that are too small to be visible with the naked eye.
                                        These microorganisms include bacteria, archaea, viruses, fungi, prions, protozoa and algae, collectively known as
                                        'microbes'.
                                    </p>
                                    <p>
                                        The right specimens from appropriate sites are collected using proper aseptic techniques. The samples are tested to
                                        identify the most common infectious agents, susceptibility tests are done by exposing bacteria and fungi to antibiotics.
                                        The antibiograms provide targeted antimicrobial therapy that will lead to better patient treatment outcomes and reducing
                                        Antimicrobial Resistance.<br/>
                                        Common specimens collected include: stool, urine, blood, (ear, nose, pus, throat swabs). Test done on the above samples
                                        is CULTURE AND SENSITIVITY
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="with_border padding_10">
                                <h4 className="entry-title semibold bottommargin_20">MOLECULAR BIOLOGY</h4>
                                <div className="entry-content">
                                    <p>
                                        Molecular Biology is the field of medicine that studies the composition, structure and interactions of cellular
                                        molecules such as nucleic acids and proteins that carry out the biological processes essential for the cells functions
                                        and maintenance.
                                    </p>
                                    <p>
                                        This involves identifying of specific DNA, RNA, PROTEINS components of the different disease causative agents from body
                                        tissues and fluids for diagnosis of diseases and further studies.<br/>
                                        Tests done include: COVID 19 PCR, Paternity Testing and other PCR tests.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="with_border padding_10">
                                <h4 className="entry-title semibold bottommargin_20">IMMUNOLOGY</h4>
                                <div className="entry-content">
                                    <p>
                                        Immunology is the study of all aspects of the immune system, including its structure and function, disorders of the
                                        immune system, blood banking, immunization and organ transplantation.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="with_border padding_10">
                                <h4 className="entry-title semibold bottommargin_20">VACCINATION</h4>
                                <div className="entry-content">
                                    <p>
                                        Yellow fever Vaccination
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="padding_10">
                                <h4 className="entry-title semibold bottommargin_20">TEST PACKAGES</h4>
                                <div className={'row'}>
                                    <div className="col-sm-4">
                                        <ul className="price-table style2">
                                            <li className="plan-name">
                                                <h3>Basic Package</h3>
                                            </li>
                                            <li className="features-list">
                                                <ul>
                                                    <li className="enabled">Complete Blood Count (CBC)</li>
                                                    <li className="enabled">Stool Analysis</li>
                                                    <li className="enabled">Urinalysis</li>
                                                    <li className="enabled">Lipid Profile</li>
                                                    <li className="enabled">RFTS</li>
                                                    <li className="enabled">LFTS</li>
                                                    <li className="disabled">Cardiac Profile</li>
                                                    <li className="disabled">Extended Electrolytes</li>
                                                    <li className="disabled">Blood Grouping Rhesus</li>
                                                    <li className="disabled">Total PSA (Men)</li>
                                                    <li className="disabled">Hemoglobin test</li>
                                                    <li className="disabled">Vitamin D</li>
                                                    <li className="disabled">Ultrasound Scan</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-sm-4">
                                        <ul className="price-table style2">
                                            <li className="plan-name">
                                                <h3>Golden Package</h3>
                                            </li>
                                            <li className="features-list">
                                                <ul>
                                                    <li className="enabled">Complete Blood Count (CBC)</li>
                                                    <li className="enabled">Stool Analysis</li>
                                                    <li className="enabled">Urinalysis</li>
                                                    <li className="enabled">Lipid Profile</li>
                                                    <li className="enabled">RFTS</li>
                                                    <li className="enabled">LFTS</li>
                                                    <li className="enabled">Cardiac Profile</li>
                                                    <li className="enabled">Extended Electrolytes</li>
                                                    <li className="disabled">Blood Grouping Rhesus</li>
                                                    <li className="disabled">Total PSA (Men)</li>
                                                    <li className="disabled">Hemoglobin test</li>
                                                    <li className="disabled">Vitamin D</li>
                                                    <li className="disabled">Ultrasound Scan</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-sm-4">
                                        <ul className="price-table style2">
                                            <li className="plan-name">
                                                <h3>Golden Package</h3>
                                            </li>
                                            <li className="features-list">
                                                <ul>
                                                    <li className="enabled">Complete Blood Count (CBC)</li>
                                                    <li className="enabled">Stool Analysis</li>
                                                    <li className="enabled">Urinalysis</li>
                                                    <li className="enabled">Lipid Profile</li>
                                                    <li className="enabled">RFTS</li>
                                                    <li className="enabled">LFTS</li>
                                                    <li className="enabled">Cardiac Profile</li>
                                                    <li className="enabled">Extended Electrolytes</li>
                                                    <li className="enabled">Blood Grouping Rhesus</li>
                                                    <li className="enabled">Total PSA (Men)</li>
                                                    <li className="enabled">Hemoglobin test</li>
                                                    <li className="enabled">Vitamin D</li>
                                                    <li className="enabled">Ultrasound Scan</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-sm-4 col-sm-offset-4">
                                        <ul className="price-table style1">
                                            <li className="plan-name">
                                                <h3>Fertility Package</h3>
                                            </li>
                                            <li className="features-list">
                                                <ul>
                                                    <li className="enabled">Ultra sound scan</li>
                                                    <li className="enabled">Fertility hormone profile test</li>
                                                    <li className="enabled">Semen analysis</li>
                                                    <li className="enabled">High vaginal swab culture & sensitivity</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className=" padding_10">
                                <h4 className="entry-title semibold bottommargin_20">Corporate Wellness Services</h4>
                                <div className="entry-content">
                                    <p>
                                        Health Management is a central issue in corporate life, as the health of employees determines the health of the
                                        Organization itself. Businesses that initiate Corporate Wellness programs aren’t only investing in the physical
                                        wellness, safety and mental health of their employees, but are also taking preventive measures to create a healthier
                                        happier workplace
                                    </p>
                                    <p>
                                        Tenna and Pharma Laboratories provides a list of tests for corporates under the corporates test package which include;
                                    </p>
                                    <ul className="list2 darklinks">
                                        <li>HIV testing and counseling</li>
                                        <li>Tuberculosis testing</li>
                                        <li>Blood sugar tests</li>
                                        <li>Cholesterol tests</li>
                                        <li>Blood pressure</li>
                                        <li>Drug abuse test</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Container>
    )
}
